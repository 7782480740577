import StripeController from './stripe_controller'
import { Modal } from 'bootstrap'

export default class extends StripeController {
  static targets = [
    'form',
    'modal',
    'stripeTokenField',
    'paymentAddressField',
    'searchedAddressField',
    'donationReceiptAgreement',
    'paymentButton',
  ]

  modalTargetConnected() {
    this.modal = new Modal(this.modalTarget)
    this.modal.show()
    this.modalTarget.addEventListener('hidden.bs.modal', (event) => {
      this.element.remove()
    })
  }

  formTargetConnected() {
    this.setupForm()
  }

  donationReceiptAgreementTargetConnected() {
    this.updateDonationReceiptAgreement()
  }
  searchedAddressFieldTargetConnected() {
    new YubinBango.MicroformatDom()
  }

  onChangeCardExisting() {
    this.data.set('method', 'existing_card')
  }

  onChangeCardNew() {
    this.data.set('method', 'new_card')
  }

  updateDonationReceiptAgreement() {
    if (this.hasPaymentButtonTarget) {
      this.paymentButtonTarget.disabled =
        !this.donationReceiptAgreementTarget.checked
    }
  }

  fillAddress(event) {
    this.paymentAddressFieldTarget.value = this.searchedAddressFieldTarget.value
    this.paymentAddressFieldTarget.focus()
  }

  async onFormSubmit(event) {
    event.preventDefault()

    if (this.data.get('method') === 'existing_card') {
      this.formTarget.submit()
      return
    }

    const result = await this.stripe.createToken(this.card)
    if (result.error) {
      return
    }

    this.setStripeTokenAndSubmit(result.token)
  }

  setStripeTokenAndSubmit(token) {
    this.stripeTokenFieldTarget.setAttribute('value', token.id)
    this.formTarget.submit()
  }
  close() {
    this.modal && this.modal.hide()
  }
}
